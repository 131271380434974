// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import {
  SEO,
  Intro,
  Title,
  Text,
  BlocksList,
  Timeline,
  Button,
} from "components";

import img1 from "../images/test-etic.png";
import img2 from "../images/04.png";
import img3 from "../images/01.png";
import img4 from "../images/08.png";
import img5 from "../images/11.png";
import img6 from "../images/05.png";

const pageProperties = {
  title: "Con metodo e etica",
  description:
    "Cerchiamo partners non clienti. I percorsi di successo si costruiscono insieme, un passo alla volta. Perciò ci sono alcune cose che devi sapere prima di iniziare.",
};

const blocks = [
  {
    title: "Non imponiamo nessun vincolo",
    description: `Quando un’azienda cambia fornitore scopre a volte che abbandonandolo perderà il proprio indirizzo web, la mail o addirittura il sito intero, che la app dovrà essere riscritta da zero, che tutti i servizi fondamentali dovranno essere resettati.
    Siamo contrari a qualsiasi pratica che trattenga il cliente contro la sua volontà o a qualsiasi “ricatto tecnico”: tutto ciò che realizziamo per te è di tua proprietà. Le registrazioni di domini e strumenti essenziali saranno fatte a tuo nome e potrai sempre monitorare.
    Qualora tu decidessi di interrompere il rapporto con noi, ti consegneremo i sorgenti del codice, i file grafici vettoriali o eventuali credenziali.
    `,
    image: img1,
  },
  {
    title: "Comunicazione e report",
    description: `La complessità del mondo digitale può lasciarti comprensibilmente spaesato di fronte a termini e strumenti che non fanno parte della tua principale attività.
    Tallonare i fornitori e cercare di interpretare risposte confuse può essere frustrante: la tua soddisfazione invece ci sta molto a cuore. Ci impegniamo quindi a tenerti aggiornato sui progressi del lavoro e a illustrarti tutti gli aspetti di quello che stiamo realizzando per te, attraverso comunicazioni e report e massima trasparenze.
    Avrai a tua disposizione un consulente dedicato che avrà sempre sotto controllo il tuo progetto.
    `,
    image: img2,
  },
  {
    title: "Partner non clienti",
    description: `La nostra aspirazione è quella di essere per la tua azienda un partner, prima ancora che un fornitore. Per questo ci mettiamo la faccia: i rapporti umani per noi hanno un valore. Sceglieremo gli strumenti e ti proporremo sempre le soluzioni più adatte al conseguimento degli obiettivi della tua azienda. Lavoreremo con passione per portarti un beneficio reale, non per venderti qualcosa di cui non hai bisogno.`,
    image: img3,
  },
  {
    title: "Questione di metodo",
    description: `Siamo fermi sostenitori di un approccio snello e agile per costruire il tuo progetto. Spesso nell’entusiasmo di voler mettere in pratica una nuova idea si corre il rischio di perdere di vista l’utile nella sua immediatezza.
    Prima di ingessarti in sviluppo e pianificazioni con tempi lunghissimi e costi elevati, aumentando i rischi per la tua azienda, procederemo a piccoli passi, misurabili e concreti.
    Potremo così tenere sotto controllo lo sviluppo del tuo progetto, i suoi costi e i risultati, attraverso un’attenta pianificazione dei rilasci e la misurazione della loro resa.
    `,
    image: img4,
  },
  {
    title: "Qualità e strumenti",
    description: `Ti garantiremo l’uso delle migliori tecnologie a disposizione e ti aiuteremo a scegliere gli strumenti più affini alle tue esigenze.
    Tutto quello di cui già disponi, se utile ed in linea con gli obiettivi prefissati, non deve essere obbligatoriamente sostituito: se è efficace, abbiamo le competenze per usare gli strumenti di cui già disponi, senza importi i nostri.
    Ciò che risparmi per gli strumenti lo puoi investire nella crescita aziendale.
    `,
    image: img5,
  },
  {
    title: " Continuità operativa e strategica",
    description: `Sappiamo bene che scegliere un fornitore nel nostro settore è soprattutto una questione di fiducia: molti clienti non comprendono cosa succede dietro la facciata della loro app o del loro portale fintanto che non arrivano i problemi.
      Noi ripaghiamo questa fiducia applicando gli standard internazionali alle nostre metodologie di sviluppo e di lavoro.
      Le nostre procedure prevedono l’uso di ambienti di lavoro distinti per ogni fase e flussi di lavoro collaudati.
      `,
    image: img6,
  },
];

const timeCards = [
  {
    title: "Analisi iniziale",
    description:
      "Qualsiasi progetto inizia con un'analisi preliminare, o non inizia. A cosa serve? Per raccogliere i dati e le informazioni che ci aiuteranno a definire la miglior soluzione per te e per raccogliere i dati di partenza con cui poi confrontare il lavoro svolto.",
    position: "left",
  },
  {
    title: "Progettazione e strategia",
    description:
      "Una volta raccolti i dati e definiti gli obiettivi di progetto definiremo come raggiugnerli. E' qui la parte più importante del nostro lavoro",
    position: "right",
  },
  {
    title: "Implementazione",
    description:
      "Un team eterogeneo ed esperto ci permette di poter poi passare alla parte pratica e mettere in atto il progetto in ogni suo aspetto",
    position: "left",
  },
  {
    title: "Misurazione e ottimizzazione",
    description:
      "Il lavoro non è finito. Una fase importante è gestire e monitorare l'andamento di quanto realizzato, andando ad intervenire laddove necessario e ottimizzando il lavoro fatto. ",
    position: "right",
  },
  {
    title: "Crescita",
    description:
      "Quando i numeri ci dicono che il livello di ottimizzazione è ottimale, è giunto il livello per te di passare al livello successivo",
    position: "left",
  },
];

function ComeLavoriamoPage() {
  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Come Lavoriamo: etica e strategia"
        description="Cerchiamo partners non clienti. I percorsi di successo si costruiscono insieme, un passo alla volta. Perciò ci sono alcune cose che devi sapere prima di iniziare."
      />

      {/* <div className="w-full md:mt-32" ref={ref}> */}
      <div className="w-screen ">
        <section className="px-8 md:px-28">
          <Intro {...pageProperties} />
        </section>
        <section className="first-chapter px-8 md:px-28 lg:pr-72">
          <Title headingLevel="h2" disableAnimation size={"small"}>
            Un partner unico per i tuoi progetti su web
          </Title>
          <Text>
            Forniamo alla tua azienda una strategia su misura per crescere sul
            web grazie ad una gamma completa di soluzioni di marketing, sviluppo
            web, e-commerce, seo, mail marketing e brand identity.
            <br />
            Smetti di inseguire fornitori diversi che non si parlano tra loro e
            che spingono solo per il proprio prodotto: sviluppiamo insieme
            quello che ti serve realmente, con unico interlocutore e un team
            dedicato.
            <br />
            Se il nostro approccio ti piace sei pronto a passare allo step
            successivo e approndire gli aspetti più tecnici del nostro lavoro.
          </Text>
        </section>
        <section className="timeline px-8 md:px-28 my-24">
          <Title headingLevel="h2" size="small">
            Il nostro metodo
          </Title>
          <Timeline cards={timeCards} />
        </section>
        <section className="px-8 md:px-28 my-24">
          <Title headingLevel="h2" size="small">
            Il nostro codice etico
          </Title>
          <BlocksList blocks={blocks} type="images" />
        </section>
      </div>
    </CleanLayout>
  );
}

export default ComeLavoriamoPage;
